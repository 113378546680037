import { useEffect, useState } from "react";
import { useCommon } from "../../hooks/useCommon";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";
import moment from "moment";
import ValuePill from "../ValuePill"

const ModifyTopAd = ({ post, updateTopAd }) => {
    const [ad, setAd] = useState()
    const [status, setStatus] = useState()
    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();


    const cancelTopAd = async () => {
        try {
            cm?.showLoader(true)
            const res = await api.cancelAdFromTop(post?.alias_id);
            console.log(res)
            if (res) {
                setAd(res)
                updateTopAd()
                alert.toast(cm?.translate("Top Ad has been canceled."), "success")
            }
            cm?.showLoader(false)
        } catch (err) {
            cm?.showLoader(false)
            alert.show(err?.message, "error");
        }
    };

    useEffect(() => {
        setAd(post)
    }, [post])

    useEffect(() => {
        if (moment() > moment(ad?.top_ads?.expire)) {
            setStatus("Expired")
        } else {
            setStatus("Running")
        }
    }, [ad])

    return <div>

        <div className="row m-0 p-2 pb-4 rounded-4 mx-3 ">
            <div className="col-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate(status)}</span>
                <div className="mt-3 d-flex flex-column align-items-start justify-content-start">
                    <img src={process.env.REACT_APP_MEDIA_URL + ad?.top_ads?.image} alt="top_ad" className="object-fit-contain" style={{ maxWidth: 250 }} />
                    <ValuePill className="mt-3" title={status === "Running" ? cm?.translate("Expire on") : cm?.translate("Expired on")} value={moment(ad?.top_ads?.expire).format("YYYY-MM-DD HH:mm")} />
                    <ValuePill className="mt-1" title={cm?.translate("Style")} value={ad?.top_ads?.type === "image-only" ? cm?.translate("Only Image") : cm?.translate("Image and Info")} />
                    {status === "Running" && <button className="btn btn-dark mt-2" onClick={cancelTopAd}>{cm?.translate("Cancel from Top Ad")}</button>}
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                {status === "Running" && <p className="my-2 my-md-0 font-size-9">{cm?.translate("Canceling Top Ad will not refund your coins, so please choose wisely.")}</p>}
            </div>
        </div>
    </div >

}
export default ModifyTopAd