import { GrNext, GrPrevious } from "react-icons/gr";
import { useCommon } from "../../hooks/useCommon"
import { useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";
import { useAuth } from "../../hooks/useAuth";

const CreateAdStepSeven = ({ navigateToStep, setNavigateToStep }) => {
    const [privacyTypes, setPrivacyTypes] = useState()
    const cm = useCommon();
    const auth = useAuth();
    const api = useApi();
    const alert = useAlert();

    const getPrivacyTypeFromApi = async () => {
        try {
            const res = await api.getPrivacyTypes();
            setPrivacyTypes(res);
        } catch (err) {
            alert.show(err?.message, "error");
        }
    };

    const handleInput = (e) => {
        cm?.setCreateAdData({ ...cm?.createAdData, privacyType: privacyTypes.find(pt => pt?.id === parseInt(e.target.value)) })
    }


    const renderPrivacyTypes = () => {
        return privacyTypes && <select className="form-select w-sm-100-md-50 font-size-12" defaultValue={cm?.createAdData?.privacyType || privacyTypes.find(pt => pt?.name === "Public")?.id} onChange={(e) => handleInput(e)}>
            {privacyTypes && privacyTypes?.map(p => <option key={"pt_" + p.id} value={p?.id}>{cm?.translate(p.name)}</option>)}
        </select>
    }

    const handleNext = () => {
        if (auth?.authUser?.alias_id && !cm?.createAdData?.member) {
            setNavigateToStep(11)
        }
        else {
            setNavigateToStep(navigateToStep + 1)
        }
    }


    const handlePrev = () => {
        if (cm?.createAdData?.categoryGroup?.name?.startsWith("Product") || cm?.createAdData?.categoryGroup?.name?.startsWith("Ticket")) {
            setNavigateToStep(navigateToStep - 1)
        } else {
            setNavigateToStep(navigateToStep - 2)
        }
    }

    useEffect(() => {
        getPrivacyTypeFromApi()
    }, [])
    useEffect(() => {
        privacyTypes && cm?.setCreateAdData({ ...cm?.createAdData, privacyType: privacyTypes?.find(pt => pt?.name === "Public") })
    }, [privacyTypes])


    return <div className="text-center">
        <h4 className="text-bg-success p-2">{cm?.translate("Privacy")}</h4>
        <div className="m-0 mt-4 mb-5 px-3 text-center">
            <p className="mb-4 font-size-9 text-start d-block">{cm?.translate("By default, the ad post is publicly visible. Users can temporarily hide the ad from buyers or restrict visibility to followers using the private option.")}</p>
            <div className="d-flex justify-content-center">
                {renderPrivacyTypes()}
            </div>
        </div>
        <div className="w-100 mt-5 mb-4 d-flex flex-row justify-content-center align-items-center">
            <button className="btn btn-dark w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handlePrev}><GrPrevious className="fs-6 me-2" /> {cm?.translate("Previous")}</button>
            <button className="btn btn-success w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handleNext}>{cm?.translate("Review the Ad")} <GrNext className="fs-6 ms-2" /></button>
        </div>
    </div>
}
export default CreateAdStepSeven