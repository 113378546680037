import { GrPrevious } from "react-icons/gr";
import { useCommon } from "../../hooks/useCommon"
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";

const VerifyOTP = ({ navigateToStep, setNavigateToStep }) => {
    const [otp, setOtp] = useState()
    const [otpDuration, setOtpDuration] = useState(180)
    const cm = useCommon();
    const auth = useAuth()
    const api = useApi();
    const alert = useAlert();


    const handleInput = (e) => {
        setOtp(e.target.value)
    }

    const handlePrev = () => {
        setNavigateToStep(8)
    }
    const handleNext = () => {
        setNavigateToStep(navigateToStep + 1)
    }

    const handleVerify = async () => {
        try {
            cm?.showLoader(true)
            const res = await api.verifyRegOtp({ otp: otp, token: cm?.otpToken });
            if (res) {
                auth.login(res);
                alert.show("Thank you. Account was verified.", "success");
                cm?.setOtpToken("")
                handleNext()
                cm?.showLoader(false)
            }
        } catch (err) {
            cm?.showLoader(false)
            if (err?.response?.status === 400) {
                alert.show(err?.response?.data?.msg || "OTP is not correct!", "error")
            } else {
                alert.show(err?.response?.data?.msg || err, "error")
            }
        }
    }

    const sendOtpAgain = async () => {
        try {
            cm?.showLoader(true)
            const res = await api.quickRegister(cm?.createAdData?.newUser);

            if (res) {
                cm?.setOtpToken(res?.token)
                cm?.showLoader(false)
                alert.toast(cm?.translate("New OTP has sent"))
                setOtpDuration(180)
            }
        } catch (err) {
            cm?.showLoader(false)
            alert.show(err?.response?.data?.msg || err, "error");
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setOtpDuration(otpDuration => {
                const newDuration = parseInt(otpDuration) - 1
                if (newDuration === 0) {
                    clearInterval(interval)
                }
                return newDuration
            })

        }, 1000)
        return () => clearInterval(interval)
    }, [])


    return <div className="text-center">
        <h4 className="text-bg-success p-2">{cm?.translate("Enter OTP")}</h4>
        <div className="m-0 mt-3 my-5 px-3 text-center">
            <p className="mb-4 font-size-9 text-center d-block">{otpDuration > 0 ? cm?.translate(`Enter OTP in ${otpDuration} seconds`) : cm?.translate(`Try again`)}</p>
            <div className="w-sm-100-md-50 mx-auto">
                <input className="form-control" name="otp" placeholder={cm?.translate("OTP")} onChange={e => handleInput(e)}></input>
            </div>
        </div>
        <div className="w-100 mt-5 mb-4 d-flex flex-row justify-content-center align-items-center">
            <button className="btn btn-dark w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handlePrev}><GrPrevious className="fs-6 me-2" /> {cm?.translate("Previous")}</button>
            <button disabled={otpDuration <= 0} className="btn btn-success w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handleVerify}>{cm?.translate("Verify")}</button>
            <button className="btn btn-light w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={sendOtpAgain}>{cm?.translate("Send OTP Again")}</button>
        </div>
    </div>

}
export default VerifyOTP 