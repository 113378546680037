import React, { useState } from "react";
import { useEffect } from "react";
import PostTemplateForMyPage from "./PostTemplateForMyPage";
import UserProfile from "../UserProfile";
import { useApi } from "../../hooks/useApi"
import { useAuth } from "../../hooks/useAuth"
import { useCommon } from "../../hooks/useCommon";
import useAlert from "../../hooks/useAlert";
import Pagination from "../Pagination";
import useRenewAuth from "../../hooks/useRenewAuth";

const MyAds = () => {
    const [backupAds, setBackupAds] = useState({})
    const [currentPage, setCurrentPage] = useState(1)

    const api = useApi();
    const auth = useAuth();
    const cm = useCommon()
    const alert = useAlert()
    const renewAuth = useRenewAuth()


    const getAdsFromApi = async (params) => {
        try {
            cm.showLoader(true);
            const res = await api?.getUserAds(auth.authUser?.alias_id, params);
            if (res) {
                cm?.setMyAds(res?.results);
                setBackupAds(res);
                cm.showLoader(false);
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm.showLoader(false);
        }
    };
    const getAdDetailFromApi = async (aliasId) => {
        try {
            cm.showLoader(true);
            const res = await api?.getAdDetail(aliasId);
            if (res) {
                const ms = cm?.myAds?.map(a => {
                    if (a?.alias_id === aliasId) {
                        return res;
                    } else {
                        return a;
                    }
                })

                cm?.setMyAds(ms)
                setBackupAds({ ...backupAds, results: ms })


                cm.showLoader(false);
            }

        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm.showLoader(false);
        }
    };

    const refreshAd = (post, action = "update") => {
        const fAds = cm?.myAds?.filter(f => f.alias_id !== post?.alias_id);
        if (action === "update") {
            const newAds = [post].concat(fAds)
            cm?.setMyAds(newAds)
        } else if (action === "delete") {
            cm?.setMyAds(fAds)
        }
    }


    useEffect(() => {
        renewAuth.renew();
        getAdsFromApi();
    }, [])



    const renderAds = () => {
        return cm?.myAds?.map((p, i) =>
            <PostTemplateForMyPage key={"post_" + i} post={p} refreshAd={refreshAd} />);
    };

    useEffect(() => {
        Object.keys(cm?.refreshAdInMyAds).length && getAdDetailFromApi(cm?.refreshAdInMyAds?.alias_id)
        renewAuth.renew();
    }, [cm?.refreshAdInMyAds])

    const onChangePage = (e) => {
        getAdsFromApi({ page: e.target.value });
        setCurrentPage(e.target.value)
    }


    return (
        <div className={""}>
            <div className="d-flex flex-column">
                <div className={"col-sm-12"}>
                    {auth.authUser && <UserProfile user={auth.authUser} />}
                </div>

                <div className="row m-0 my-3" >{renderAds()}</div>

                <div className="mt-4 mb-5">
                    <Pagination totalItems={backupAds?.count} onChangePage={onChangePage} currentPage={currentPage} />
                </div>

            </div>
        </div >
    );
};
export default MyAds;
