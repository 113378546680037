import { GrNext, GrPrevious } from "react-icons/gr";
import { useCommon } from "../../hooks/useCommon"

const SetMemberFor = ({ navigateToStep, setNavigateToStep }) => {
    const cm = useCommon();

    const handleInput = (e) => {
        cm?.setCreateAdData({ ...cm?.createAdData, member: e.target.value })
    }

    const handleNext = () => {
        setNavigateToStep(navigateToStep)
    }

    return <div className="text-center">
        <h4 className="text-bg-success p-2">{cm?.translate("Set Member For")}</h4>
        <div className="m-0 mt-3 my-5 px-3 d-flex justify-content-center">
            <input className="form-control w-sm-100-md-50" name="email_or_phone" placeholder={cm?.translate("Email or Phone")} onChange={e => handleInput(e)}></input>
        </div>
        <div className="w-100 mt-5 mb-4 d-flex flex-row justify-content-center align-items-center">
            <button disabled={!cm?.createAdData?.member} className="btn btn-success w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handleNext}>{cm?.translate("Next")} <GrNext className="fs-6 ms-2" /></button>
        </div>
    </div>

}
export default SetMemberFor 